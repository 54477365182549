
import { defineComponent } from "vue";
import axios from "axios";
import ApiService from "@/core/services/ApiService";
import { DateHelper } from "@/Utilities/DateHelper";
import BarChart from "@/components/Charts/BarChart.vue";
//import html2pdf from 'html2pdf.js';

interface MonthlyReportData {
  clinicName: string;
  previousMonth: string;
  currentMonth: string;
  monthComparisons: Array<MonthComparison>;  
  dueButMissedByProvider: Array<DueButMissed>;  
  yoyChart: Chart;
  patientCountChart: Chart;  
  procedureDistributionChart: Chart;  
  perHour: PerHour
}

interface Chart{
  xaxisValues: Array<string>;
  series: Array<Series>;
}
interface Series{
  name: string;
  data: Array<number>;
}

interface DueButMissed{
  providerName: string;
  procedures: Array<DueButMissedPercentages>;
}

interface DueButMissedPercentages{
  procedureName: string;
  previousMonthPercentage: number;
  currentMonthPercentage: number;
}

interface MonthComparison{
  providerName: string;
  rnaUsagePercentage: number;
  previousMonthPerHour: number;
  currentMonthPerHour: number;
  

  //calculated fields
  increase: number | null;
  increasePercentage: number | null;
}

//public record PerHour(decimal? CurrentMonthPerHour, int? Percentile);
interface PerHour{
  currentMonthPerHour: number;
  percentile: number;
}

export default defineComponent({
  components: { BarChart },
  data: function() {
    let endDateToUse = new Date();
		endDateToUse.setDate(endDateToUse.getDate() - 1);

    return {
      GeneralInformation: [],
      HygieneResourcing: [],
      ProcedureDistribution: [],
      type: "bar",
      height: "100%",
      width: "100%",
      series: [],
      chartOptions: null,      
      reportData: null as MonthlyReportData | null,
      DateHelper: DateHelper,
      xaxisValues: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct','Nov','Dec'],
      yoySeries: null as any,
      startDate: `${new Date().getFullYear()-2}-01-01`,
      endDate: endDateToUse.toISOString().split("T")[0],	
      patientCountSeries: null as any,
      patientCountXaxisValues: null as any,
      procedureDistributionXaxisValues: null as any,
      procedureDistributionSeries: null as any,
      distinctDueButMissedProcedures: null as any,
      chunkedDueButMissedByProvider: null as Array<Array<DueButMissed>> | null
    };
  },
  methods:{
    async fetchAssessmentReport() {      
        let assessmentReport = await ApiService.get("Report", "Assessment");        
        assessmentReport.data = assessmentReport.data.filter((x: any) => !x["MonthDate"]);
        this.GeneralInformation = assessmentReport.data.filter((x: any) => Object.keys(x).length == 1);
        this.HygieneResourcing = assessmentReport.data.filter((x: any) => Object.keys(x).length > 1 && Object.keys(x).length < 3);
        this.ProcedureDistribution = assessmentReport.data.filter((x: any) => Object.keys(x).length > 2);      
    },
    formatCamelCase(str: any) {
      return str.replace(/([a-z])([A-Z0-9])/g, '$1 $2').replace(/([0-9])([A-Z])/g, '$1 $2');
    },
    downloadPDF() {
      const element = document.getElementById('pdf-content');
      
      const options = {
        // margin:       1,
        // filename:     `test.pdf`,
        // image:        { type: 'jpeg', quality: 0.98 },
        // html2canvas:  { scale: 2 },
        // jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
      };

      //html2pdf().from(element).set(options).save();
    }
  },
  async mounted() {    
    document.body.style.backgroundColor = "#ffffff";
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `
      @page {
        size: A4;
        margin: 20mm;
      }         
    `;
    document.head.appendChild(style);

    let baseUrl = ApiService.vueInstance.axios.defaults.baseURL;
    let url = new URL(window.location.href);
    let dataToken = url.searchParams.get("dataToken");
    //make axios get request to ApiService.vueInstance.axios.defaults.baseURL
    let res = null as any;
    if (dataToken) {    
      res = await axios.get(`${baseUrl}/Agent/ReportData?dataToken=${dataToken}`);    
      if (res.status != 200) {
        console.error("Error getting report data");
        return;
      }
    }
    else {
      //only works if logged in
      
      console.log("checking currentuser", this.$store.getters.currentUser)
      if (!this.$store.getters.currentUser) {
        
        //redirect to login screen
        this.$router.push({ name: 'login', query: { redirectFrom: this.$route.fullPath } });
        return;

        //next({ name: 'login', query: { redirectFrom: to.fullPath } })
      }

      // Accept a date input from the URL to enable custom date selection for report data
      let fromDate = url.searchParams.get("fromdate");     

      const params = {
        date: fromDate,
      };
      
      res = await ApiService.query("Report/Monthly", {
        params,
      }); 

      console.log("res", res);
      if (res.status != 200) {
        console.error("Error getting report data");
        return;
      }
    }
    

    this.reportData = res.data as MonthlyReportData;
    //let's precalculate a few things
    this.reportData?.monthComparisons.forEach((comparison) => {
      comparison.rnaUsagePercentage = Math.round(comparison.rnaUsagePercentage);
      comparison.increase = (comparison.previousMonthPerHour === null || comparison.currentMonthPerHour === null)  ? null : Math.round(comparison.currentMonthPerHour - comparison.previousMonthPerHour);
      comparison.increasePercentage = comparison.increase === null ? null : Math.round((comparison.increase / comparison.previousMonthPerHour) * 100);
    });

    this.yoySeries = this.reportData?.yoyChart.series;
    this.patientCountSeries = this.reportData?.patientCountChart.series;
    this.patientCountXaxisValues = this.reportData?.patientCountChart.xaxisValues;
    this.procedureDistributionSeries = this.reportData?.procedureDistributionChart.series;
    this.procedureDistributionXaxisValues = this.reportData?.procedureDistributionChart.xaxisValues;
    this.distinctDueButMissedProcedures = this.reportData.dueButMissedByProvider.map(x => x.procedures).flat().map(x => x.procedureName).filter((v, i, a) => a.indexOf(v) === i);

    //chunk providers in groups of 7
    this.chunkedDueButMissedByProvider = this.reportData.dueButMissedByProvider
                                              .filter(d => d.procedures.length > 0)
                                              .reduce((resultArray: Array<Array<DueButMissed>>, item: DueButMissed, index: number) => { 
      const chunkIndex = Math.floor(index/5)
      
      if(!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }
      
      resultArray[chunkIndex].push(item)
      
      return resultArray
    }, [])
    
    await this.fetchAssessmentReport();
    //
    //parse URL as chartoptions. URL is in base64 format and param is ?chart=<<base64>>
      
    // if (chart){
    //   let options = JSON.parse(atob(chart));      
      
    //   this.series = options.series;
    //   this.type = options.type;
    //   this.height = options.height;
    //   this.width = options.width;
    //   this.chartOptions = options.chartOptions;
    // }    


    
  },
});
