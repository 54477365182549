
import { defineComponent, computed, customRef } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Avatar from "@/components/Avatar.vue";
import { LOCAL_STORAGE_CONST } from "@/Models/local-storage.model";

export default defineComponent({
  name: "kt-user-menu",
  components: {Avatar},
  data(){
    return {
      clinicNameFilter: "",
      clickedCustomers: [] as any,
    }
  },
  computed:{
    filteredClinics(): any {
      let clinics = [] as any;
      if(this.clinicNameFilter && this.clinicNameFilter != ""){
        clinics = this.searchForClinic(this.clinicNameFilter);
      }else{
        clinics = this.currentUser.clinics;
      }
      //group clinics by customerName such that every element has a "customerName" property and another property that is the list of clinics
      const clinicsByCustomer = Object.values(
        clinics.reduce((group, item) => {          
          const { customerName } = item;
          if (!group[customerName]) {
            group[customerName] = { customerName, clinics: [] }; // Initialize group
          }

          group[customerName].clinics.push(item); // Add clinic to the children array
          return group;
        }, {})
      ).map(group => ({
        ...(typeof group === 'object' ? group : {}),
        clinics: (group as { clinics: any[] }).clinics.sort((a, b) => a.name.localeCompare(b.name)) // Sort children by name
      })).sort((a, b) => ((a as any).customerName || '').localeCompare((b as any).customerName || '')); // Sort by customerName;

      
      console.log("clinicsByCustomer", clinicsByCustomer);
      
      return clinicsByCustomer;
      
      //return clinics.sort((a, b) => (a.customerName + " " + a.name).localeCompare(b.customerName + " " + b.name))

    },
  },
  methods: {
    expandCustomer(customerName){
      let something = this.clickedCustomers.some(x => x == customerName);
      if (!this.clickedCustomers.some(x => x == customerName)) {
        this.clickedCustomers.push(customerName);
      }
      else {
        this.clickedCustomers = this.clickedCustomers.filter(x => x != customerName);
      }      
    },
    showClinics(customerName){
      return this.clinicNameFilter != "" || this.clickedCustomers.some(x => x == customerName);
    },
    async switchClinic(clinicGuid){
      localStorage.setItem(LOCAL_STORAGE_CONST.ClinicGuid, clinicGuid);
      if (this.$router.currentRoute.value.path.includes('/appointment/')) {
        window.location.assign('/dashboard')
         return;
      } 
      window.location.reload();
    },
    logOut(hint_email) {
      this.$router.push("/logout")

      // this.$msalInstance.logoutRedirect()
      //   .then(() => {
      //     this.$emit('logout', 'logging out');
      //   })
      //   .catch(error => {
      //     console.error(error);
      //   });
    },
    searchForClinic(e){
      let targetWords = e;
      let filtered = this.currentUser.clinics.filter(x=> (x.customerName.toLowerCase() + " " + x.name.toLowerCase()).includes(targetWords.toLowerCase()));
      console.log(filtered)
      return filtered;
    },
  },  
  setup() {
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();
    i18n.locale.value = localStorage.getItem(LOCAL_STORAGE_CONST.lang)
      ? (localStorage.getItem(LOCAL_STORAGE_CONST.lang) as string)
      : "en";

    const countries = {
      en: {
        flag: "/media/flags/united-states.svg",
        name: "English",
      },
      es: {
        flag: "/media/flags/spain.svg",
        name: "Spanish",
      },
      de: {
        flag: "/media/flags/germany.svg",
        name: "German",
      },
      ja: {
        flag: "/media/flags/japan.svg",
        name: "Japanese",
      },
      fr: {
        flag: "/media/flags/france.svg",
        name: "French",
      },
    };

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    };

    const setLang = lang => {
      localStorage.setItem(LOCAL_STORAGE_CONST.lang, lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = lang => {
      return i18n.locale.value === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[i18n.locale.value];
    });

    const currentUser = computed(() => {
      return store.getters.currentUser;
    });

    const isMultCustomer = computed(() => {
      const customerNames = currentUser.value.clinics.map(clinic => clinic.customerName);
      const uniqueCustomerNames = new Set(customerNames);
      return uniqueCustomerNames.size > 1;
    });

    //currentUser.value.clinics
    //check to see if i have more than one customer represented in currentUser.value.clinics[x].customerName



    return {
      signOut,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      countries,
      currentUser,
      isMultCustomer,
    };
  }
});
