const DocMenuConfig: object = [
  {
    pages: [      
      {
        heading: "Dashboard",
        route: "/mydashboard",
        svgIcon: "/media/icons/duotone/Design/PenAndRuller.svg",
        fontIcon: "bi-app-indicator"
      },    
      {
        heading: "Calendar",
        route: "/dashboard",
        svgIcon: "/media/icons/duotone/Interface/Calendar.svg",
        fontIcon: "bi-app-indicator"
      },
      {
        heading: "Insights",
        route: "/insights",
        svgIcon: "/media/icons/duotone/Interface/Scatter-Up.svg",
        fontIcon: "bi-app-indicator"
      },
      {
        sectionTitle: "Charts",
        route: "/charts",
        svgIcon: "/media/icons/duotone/Shopping/Chart.svg",
        fontIcon: "bi-layers",        
        sub: [
          {
            heading: "Productivity Chart",
            route: '/charts/productivity',
            requiresAdmin: true
          },
          {
            heading: "RNA Usage",
            route: '/charts/dailies',
            requiresAdmin: true
          },
          {
            heading: "Year Over year",
            route: "/charts/yoy",
            requiresAdmin: false,
            superAdmin: false
          },
          {
            heading: "Dr Year Over year",
            route: "/charts/Dryoy",
            requiresAdmin: true,            
          },
          {
            heading: "Open Time",
            route: "/opentimedashboard",            
            requiresAdmin: true,
          },
        ]
      },
      {
        sectionTitle: "Reports",
        route: "/reports",
        svgIcon: "/media/icons/duotone/General/Clipboard.svg",
        fontIcon: "bi-layers",
        sub: [
          {
            heading: "Payroll",
            route: '/reports/payroll',
            requiresAdmin: false,
            checkPayrollVisibility: true, //more hacks
          },
          {
            heading: "Assessment",
            route: "/reports/assessment",
            superAdmin: true
          },
          // {
          //   heading: "Sterilization Records",
          //   route: "/reports/sterilizationrecords"
          // },
          {
            heading: "Productivity Report",
            route: "/reports/productivityreport"
          },
          {
            heading: "Productivity By Day",
            route: "/reports/productivityreportbyday",
          },
          {
            heading: "Usage report",
            route: "/reports/usagereport",
            superAdmin: true
          },
          {
            heading: "Custom reports",
            route: "/reports/otherreports",        
            requiresAdmin: true
          },
          {
            heading: "Data Explorer",
            route: "/DataExplorer",        
            superAdmin: true,            
          },
        ]
      },
      {
        sectionTitle: "settings",
        route: "/settings",
        svgIcon: "/media/icons/duotone/Interface/Settings-02.svg",
        fontIcon: "bi-layers",
        sub: [
          // {
          //   heading: "Sterilizers",
          //   route: "/settings/sterilizers"
          // },
          // {
          //   heading: "Packages",
          //   route: "/settings/packages",
          //   requiresPermission: true
          // }, {
          //   heading: "BI Lots",
          //   route: "/settings/BI"
          // },
          {
            heading: "DNA",
            route: '/settings',
            requiresAdmin: true
          },
          {
            heading: "User Management",
            route: '/settings/users',
            requiresAdmin: true
          },
          {
            heading: "Providers",
            route: '/settings/providers',
            requiresAdmin: true
          }, 
          {
            heading: "Developer Hub",
            route: '/settings/sqlExplorer',            
            superDeveloper: true
          },
          
        ]
      },
      // {
      //   sectionTitle: "More...",
      //   route: "",
      //   svgIcon: "/media/icons/duotone/Communication/More.svg",
      //   fontIcon: "",
      //   sub: [
      //     {
      //       heading: "Guides/ MIFUs",
      //       route: "/guides"
      //     },
      //   ]
      // },
    ]
  },
  /*  {
      heading: "craft",
      route: "/crafted",
      pages: [
        {
          sectionTitle: "pages",
          route: "/pages",
          svgIcon: "/media/icons/duotone/Code/Compiling.svg",
          fontIcon: "bi-archive",
          sub: [
            {
              sectionTitle: "profile",
              route: "/profile",
              sub: [
                {
                  heading: "profileOverview",
                  route: "/crafted/pages/profile/overview"
                },
                {
                  heading: "projects",
                  route: "/crafted/pages/profile/projects"
                },
                {
                  heading: "campaigns",
                  route: "/crafted/pages/profile/campaigns"
                },
                {
                  heading: "documents",
                  route: "/crafted/pages/profile/documents"
                },
                {
                  heading: "connections",
                  route: "/crafted/pages/profile/connections"
                },
                {
                  heading: "activity",
                  route: "/crafted/pages/profile/activity"
                }
              ]
            },
            {
              sectionTitle: "wizards",
              route: "/wizard",
              sub: [
                {
                  heading: "horizontal",
                  route: "/crafted/pages/wizards/horizontal"
                },
                {
                  heading: "vertical",
                  route: "/crafted/pages/wizards/vertical"
                }
              ]
            }
          ]
        },
        {
          sectionTitle: "account",
          route: "/account",
          svgIcon: "/media/icons/duotone/General/User.svg",
          fontIcon: "bi-person",
          sub: [
            {
              heading: "accountOverview",
              route: "/crafted/account/overview"
            },
            {
              heading: "settings",
              route: "/crafted/account/settings"
            }
          ]
        },
        {
          sectionTitle: "authentication",
          svgIcon: "/media/icons/duotone/Interface/Lock.svg",
          fontIcon: "bi-sticky",
          sub: [
            {
              sectionTitle: "basicFlow",
              sub: [
                {
                  heading: "signIn",
                  route: "/sign-in"
                },
                {
                  heading: "signUp",
                  route: "/sign-up"
                },
                {
                  heading: "passwordReset",
                  route: "/password-reset"
                }
              ]
            },
            {
              heading: "error404",
              route: "/404"
            },
            {
              heading: "error500",
              route: "/500"
            }
          ]
        },
        {
          sectionTitle: "modals",
          route: "/modals",
          svgIcon: "/media/icons/duotone/Design/Substract.svg",
          fontIcon: "bi-shield-check",
          sub: [
            {
              sectionTitle: "general",
              route: "/general",
              sub: [
                {
                  heading: "inviteFriends",
                  route: "/crafted/modals/general/invite-friends"
                },
                {
                  heading: "viewUsers",
                  route: "/crafted/modals/general/view-user"
                },
                {
                  heading: "upgradePlan",
                  route: "/crafted/modals/general/upgrade-plan"
                },
                {
                  heading: "shareAndEarn",
                  route: "/crafted/modals/general/share-and-earn"
                }
              ]
            },
            {
              sectionTitle: "forms",
              route: "/forms",
              sub: [
                {
                  heading: "newTarget",
                  route: "/crafted/modals/forms/new-target"
                },
                {
                  heading: "newCard",
                  route: "/crafted/modals/forms/new-card"
                },
                {
                  heading: "newAddress",
                  route: "/crafted/modals/forms/new-address"
                },
                {
                  heading: "createAPIKey",
                  route: "/crafted/modals/forms/create-api-key"
                }
              ]
            },
            {
              sectionTitle: "wizards",
              route: "/wizards",
              sub: [
                {
                  heading: "twoFactorAuth",
                  route: "/crafted/modals/wizards/two-factor-auth"
                },
                {
                  heading: "createApp",
                  route: "/crafted/modals/wizards/create-app"
                },
                {
                  heading: "createAccount",
                  route: "/crafted/modals/wizards/create-account"
                }
              ]
            }
          ]
        },
        {
          sectionTitle: "widgets",
          route: "/widgets",
          svgIcon: "/media/icons/duotone/Layout/Layout-4-blocks.svg",
          fontIcon: "bi-layers",
          sub: [
            {
              heading: "widgetsLists",
              route: "/crafted/widgets/lists"
            },
            {
              heading: "widgetsStatistics",
              route: "/crafted/widgets/statistics"
            },
            {
              heading: "widgetsCharts",
              route: "/crafted/widgets/charts"
            },
            {
              heading: "widgetsMixed",
              route: "/crafted/widgets/mixed"
            },
            {
              heading: "widgetsTables",
              route: "/crafted/widgets/tables"
            },
            {
              heading: "widgetsFeeds",
              route: "/crafted/widgets/feeds"
            }
          ]
        }
      ]
    },
    {
      heading: "apps",
      route: "/apps",
      pages: [
        {
          sectionTitle: "chat",
          route: "/chat",
          svgIcon: "/media/icons/duotone/Communication/Group-chat.svg",
          fontIcon: "bi-chat-left",
          sub: [
            {
              heading: "privateChat",
              route: "/apps/chat/private-chat"
            },
            {
              heading: "groupChat",
              route: "/apps/chat/group-chat"
            },
            {
              heading: "drawerChat",
              route: "/apps/chat/drawer-chat"
            }
          ]
        }
      ]
    }*/
];

export default DocMenuConfig;
