
	import ApiService from "@/core/services/ApiService";
	import { format, quartersInYear } from "date-fns";
	import BarChart from "@/components/Charts/BarChart.vue";
	import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
	import {codes} from "@/Codes/Codes";
	import {useToast} from "vue-toastification";
	import { createApp, defineComponent, ref } from "vue";
	export default defineComponent({
		props: {
			//series: { type: Array },
			//categories: { type: Array },
		},
		components: { BarChart },
		data() {

			let endDateToUse = new Date();
			endDateToUse.setDate(endDateToUse.getDate() - 1);
			

			return {
				loading: false,
        		toast: useToast(),				
				startDate: `${new Date().getFullYear()}-01-01`,
				endDate: endDateToUse.toISOString().split("T")[0],			
				
				reportList: [] as any,				
				selectedReport: null,
				monthYear: new Date().getFullYear() + "-" + (new Date().getMonth()+1).toString().padStart(2, "0"),
				quarter: 1,
				//default to current year as a number and not a string
				year: new Date().getFullYear(),
				selectedReportParameters: null as any,
			};
		},
		methods: {	
			getMaxDate() {
				const currentDate = new Date();
				if (this.selectedReport == "CustomMonthly") {
					currentDate.setMonth(currentDate.getMonth() - 1);				
				}
				
				return currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1).toString().padStart(2, '0');
			},
			selectedReportChanged() {
				let report = this.reportList.find((r: any) => r.name === this.selectedReport);
				this.selectedReportParameters = report.parameters;
				this.monthYear = this.getMaxDate();
			},
			async getReports() {				
				let res = null as any | null;				
				res = await ApiService.query("Report", null as any);
				this.reportList = res.data;			
				this.selectedReport = this.reportList[0].name;
				this.selectedReportChanged();	
			},
			async generateReport() {
				//download a file by calling EmailReports?reportName=DoctorReport&year=2023&month=10
				this.loading = true;
				try {
					let res = null as any | null;
					
					let month = null as any;
					let quarter = null as any;
					let year = null as any;

					if (this.selectedReportParameters === 2){
						if (this.monthYear){
							month = this.monthYear.split("-")[1];
							year = this.monthYear.split("-")[0];
						}
					} else if (this.selectedReportParameters === 3){
						month = null;
						year = this.year;
					} else if (this.selectedReportParameters === 4){
						quarter = this.quarter;
						year = this.year;
					}  
					
					let params = {
						reportName: this.selectedReport,					
						month: month,
						quarter: quarter,
						year: year,
						startDate: this.startDate,
						endData: this.endDate
					}

					if (this.selectedReport == "CustomMonthly") {
						//let date = this.monthYearCustomMonthly;
						window.open("/GenerateChart?fromdate=" + this.monthYear, "_blank");
					}
					else {
						res = await ApiService.query("EmailReports", { params, responseType: "blob"});
					
						//download file to user's computer in res.data
						if (res.data) {
							const url = window.URL.createObjectURL(new Blob([res.data]));
							const link = document.createElement("a");
							link.href = url;
							link.setAttribute("download", `${this.selectedReport}.xlsx`);
							document.body.appendChild(link);
							link.click();
						}
					}
				}
				catch (e) {
					this.toast.error(codes.error);
					throw e;
				}
				finally {
					this.loading = false;
				}
			},
		},
		async mounted() {
			setCurrentPageTitle("Additional Reports");
			await this.getReports();
		},
	});
