
import { PivotViewComponent, FieldList, GroupingBar, Grouping, CalculatedField, Toolbar, PivotChart, NumberFormatting, ConditionalFormatting   } from "@syncfusion/ej2-vue-pivotview";
import ApiService from "@/core/services/ApiService";
import { codes } from "@/Codes/Codes";
import { defineComponent } from "vue";
import { useToast } from "vue-toastification";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { DateHelper } from "@/Utilities/DateHelper";

export default defineComponent({

    components: { "ejs-pivotview" : PivotViewComponent },
    provide: {  
    // Injecting the required modules for additional features.
    pivotview: [
        GroupingBar, 
        FieldList, 
        CalculatedField, 
        Grouping, 
        Toolbar, 
        PivotChart,
        NumberFormatting,ConditionalFormatting  ],
    },
    data() {
        return {
            loading: false,
            reportList: [] as any,
            toast: useToast(),
            dataSourceSettings: null as any,
            reportName: "",
            selectedReport: null as any,            
            showFieldList: true,
            showGroupingBar: true,
            showToolbar: true,
            toolbar: ["Grid", "Chart","Export", 
                        "SubTotal", "GrandTotal", "ConditionalFormatting", "NumberFormatting"] as any,
            allowCalculatedField: true,
            displayOption: { view: "Both" },
            height: '650px',
            width: 'auto'
        };
    }, 
    

    async mounted() {
        await this.getProviderData();
        setCurrentPageTitle("Data Explorer");
    },
    methods: {
        async saveBtnClick(layoutId: number | null, name: string) {
            if (name === "") {
                this.toast.error("Please enter a name for the report");
                return;
            }

            let pivotGridObj = ((document?.getElementById('pivotview')) as any).ej2_instances[0];
            let layout = JSON.parse(pivotGridObj.getPersistData());

            //strip out the data found in the datasource
            delete layout.dataSourceSettings.dataSource;
            delete layout.pivotValues
            console.log("layout", layout);
            await ApiService.post(`Report/DataExplorer/Layout/${layoutId ?? ''}`, { layoutName: name, layout: JSON.stringify(layout) } as any);
            this.selectedReport.layoutName = name;

            this.toast.success("Report saved successfully");
        },
        loadReport() {
            let pivotGridObj = ((document?.getElementById('pivotview')) as any).ej2_instances[0];
            pivotGridObj.loadPersistData(this.selectedReport.layout);
            this.reportName = this.selectedReport.layoutName;
        },
        async getProviderData() {
        try {
            let layouts = await ApiService.get("Report/DataExplorer/Layout");
            if (layouts.status === 200) {
                this.reportList = layouts.data;
                this.reportList.push({ layoutName: "New", layout: null, layoutId: null });
                this.selectedReport = this.reportList[this.reportList.length - 1];
            } else {
                this.toast.error(codes.error)
            }
            
            const params = {                
            }
            let res = await ApiService.query("Report/DataExplorer", { params });

            if (res.status === 200) {
                // console.log("res.data", res.data);
                // let providerProd = {
                //     data: res.data.data.map(d => {
                //         d.totalHoursWorked = Math.trunc(d.totalMinutesWorked / 60);
                //         if (d.percentProductiveTime) {
                //             d.openTime = 100 - (d.percentProductiveTime * 100);
                //         } else {
                //             d.openTime = null;
                //         }

                //         return d;
                //     }),
                //     totals: res.data.totals.map(d => {
                //         d.totalHoursWorked = Math.trunc(d.totalMinutesWorked / 60);
                //         if (d.percentProductiveTime) {
                //             d.openTime = Math.round(100 - (d.percentProductiveTime * 100));
                //         } else {
                //             d.openTime = null;
                //         }
                //         return d;
                //     })
                // };
                res.data.forEach(d => { 
                                        var date = DateHelper.getDatePortionFromString(d.date);
                                        d.Day  = date;
                                        d.Year = date.getFullYear();
                                        //get month like this 'Jan', 'Feb', 'Mar' etc
                                        d.MonthName = date.toLocaleString('default', { month: 'short' });
                                        d.Month = date.getMonth() + 1;
                                                                                
                                    });
                console.log("providerProd", res.data);
                this.dataSourceSettings  = {
                    allowValueFilter: true,
                    allowMemberFilter: true,
                    allowLabelFilter: true,                    
                    dataSource: res.data,
                    formatSettings: [ { name: 'date', type: 'date', format: 'yyyy-MM-dd' }], 
                    rows: [{ name: 'clinicName' }], 
                    //columns: [{ name: 'amt' }], 
                    values: [{ name: 'perHour', caption: 'Per hour', type: 'Avg' }, ], 
                    filterSettings : [{ name: 'date', type: 'Date', condition: 'Between', value1: '2024-06-01', value2: '2024-06-02' }],
                    //formatSettings: [{ name: 'totalHoursWorked', format: 'C1' }, { name: 'openTime', format: 'C1' }], 
                    //calculatedFieldSettings: [{ name: 'Total', formula: '"Sum(Amount)"+"Sum(Quantity)"' }
                };
                //converting minutesworked into hours worked
                //res.data.map(d => d.hoursWorked = (d.minutesWorked/60));
                //this.bindData(providerProd, "perHour");
            } else {
                this.toast.error(codes.error)
            }
        } catch (e) {
            this.toast.error(codes.error)
            throw (e);
        }
    }

    },     
    
})

