
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar, { CalendarApi } from "@fullcalendar/vue3";
import { createApp, defineComponent, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import dayGridPlugin from "@fullcalendar/daygrid";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Modal from "../Modal.vue";
import { ProductionCalculationType } from "@/ProcedureRules/ProductionCalculationType";

var showDismissed = false;
var currentAppointment;
var toggling = 0;

export enum InsightType
{
    AlmostDue = 1,
    NoDoctor = 2,
    Under12 = 3,
    Birthday = 4
}

export default defineComponent({
    name: "CalendarController",
    components: { FullCalendar, Modal },
    data() {
        return {
            loading: false,
            calendarKey: 1,
            showInsightsModal: false,
            dismissInsights: false,
            isModalOpen: false,
            dismissedAppointmentInsights: {
                insights: [] as any,
            },
            appointmentInsights: {
                insights: [] as any,
            },
            insights: {
                insights: {} as any,
            },
            calendarOptions: {
                //callendar options in extendedProps we need to add ishtml to yes and add a tasksarray tasks array will show as list in callendar and as a list in a table in modal under opted services
                plugins: [dayGridPlugin],
                headerToolbar: {
                    left: "prev,next,today",
                    center: "title",
                    right: "",
                },
                eventDidMount: (info: any) => {
                    info.el.title = this.GetDescription(info);

                    if (info.event.extendedProps.allInsightsDismissed && !showDismissed) {
                        info.el.classList.add('dismissedHidden');
                        info.el.classList.remove('dismissed');
                        info.el.classList.add("hide");
                    }

                    else if (info.event.extendedProps.allInsightsDismissed) {
                        info.el.classList.remove("dismissedHidden");
                        info.el.classList.add("dismissed");
                        info.el.classList.remove("hide");
                    }

                    info.el.classList.add("pointer");

                    info.el.addEventListener('click', () => {
                        this.ShowInsights(info.event.extendedProps.apptId);
                    });
                },                               
                timeZone: "America/New_York",
                initialView: "dayGridMonth",                
                nowIndicator: true,
                
                height: "calc(100vh - 295px)", //makes the calendar with bigger height to have more room for tiny events                
                expandRows: true,                                                
                events: [] as any,                
            }
        };
    },
    methods:{
        GetDescription(item) {
            let insights = item.event.extendedProps.insights;

            let description: string[] = [];

            description.push(insights
                .filter((insights) => !insights.dismissed)
                .map((insights) => insights.description)
                .join("\n"));

            description.push(insights
                .filter((insights) => insights.dismissed)
                .map((insights) => "Dismissed: " + insights.description)
                .join("\n"));

            return description.join("\n");
        },
        FormatDate(date) {
            const formattedDate = new Date(date);

            const year = formattedDate.getFullYear();
            const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
            const day = String(formattedDate.getDate()).padStart(2, '0');

            let hours = formattedDate.getHours();
            const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
            const period = hours >= 12 ? 'p.m.' : 'a.m.';
            hours = hours % 12 || 12;

            return `${year}-${month}-${day}, ${hours}:${minutes} ${period}`;
        },
        ShowInsights(id) {
            currentAppointment = id;
            this.insights.insights = this.calendarOptions.events.filter(i => i.apptId == id);
            
            this.showInsightsModal = true;
        },
        CloseInsights() {
            this.showInsightsModal = false;
        },
        async DismissInsight(appt, proc, insightType) {
            let params = {
                appointmentId: appt,
                procedureRuleTypeId: proc,
                InsightTypeId: insightType,
                dismiss: true
            };

            let dismissed = await ApiService.query("appointment/dismissinsight", { params });

            if (dismissed.data)
            {
                let dismissedInsights = 0;
                let totalApptInsightsCount = this.insights.insights[0].insights.length;

                for (var i = 0; i < totalApptInsightsCount; i++) {
                    let item = this.insights.insights[0].insights[i]; 
                    if (item.procedureRuleTypeId == proc && item.insightTypeId == insightType) {
                        item.dismissed = true;
                        dismissedInsights++;
                    }
                    else
                        if (item.dismissed)
                            dismissedInsights++;
                }

                for (var i = 0; i < this.calendarOptions.events.length; i++) {
                    let item = this.calendarOptions.events[i];
                    if (item.apptId == appt) {
                        item.allInsightsDismissed = dismissedInsights == totalApptInsightsCount;
                        break;
                    }
                }

                
            }
        },
        async UnDismissInsight(appt, proc, insightType) {
            let params = {
                appointmentId: appt,
                procedureRuleTypeId: proc,
                InsightTypeId: insightType,
                dismiss: false
            };

            let dismissed = await ApiService.query("appointment/dismissinsight", { params });

            if (dismissed.data)
            {
                let dismissedInsights = 0;
                let totalApptInsightsCount = this.insights.insights[0].insights.length;

                for (var i = 0; i < totalApptInsightsCount; i++) {
                    let item = this.insights.insights[0].insights[i]; 
                    if (item.procedureRuleTypeId == proc && item.insightTypeId == insightType) {
                            item.dismissed = false;
                        }
                        else
                            if (item.dismissed)
                                dismissedInsights++;
                }

                for (var i = 0; i < this.calendarOptions.events.length; i++) {
                    let item = this.calendarOptions.events[i];
                    if (item.apptId == appt) {
                        item.allInsightsDismissed = dismissedInsights == totalApptInsightsCount;
                        break;
                    }
                }
            }
        },
        ViewAppointment(event) {
            window.open(
                '/appointment/' + currentAppointment,
                '_blank');
        },
        async Insights(event) {

        },
        async ToggleDismissed() {
            if (toggling != 0) return;

            if (!showDismissed) {              
                Array.from(document.querySelectorAll('.dismissedHidden')).forEach(function(el) {
                    if (toggling == 0) {
                        document.getElementById("dismissToggle")?.setAttribute("disabled", "disabled");
                    }

                    toggling++;
                    let thisElement = el as HTMLElement;
                    thisElement.classList.remove("hide");
                    thisElement.offsetHeight;
                    thisElement.classList.remove('dismissedHidden');
                    thisElement.classList.add('dismissed');

                    el.addEventListener("transitionend", () => {
                        if (--toggling == 0)  {
                            document.getElementById("dismissToggle")?.removeAttribute("disabled");
                        }
                    }, { once: true });
                });

            }
            else {
                Array.from(document.querySelectorAll('.dismissed')).forEach(function(el) {    
                    if (toggling == 0) {
                        document.getElementById("dismissToggle")?.setAttribute("disabled", "disabled");
                    }

                    toggling++;
                    el.classList.add('dismissedHidden');
                    el.classList.remove('dismissed');

                    el.addEventListener("transitionend", () => {
                        el.classList.add("hide");    
                        if (--toggling == 0)  {            
                            document.getElementById("dismissToggle")?.removeAttribute("disabled");
                        }
                    }, { once: true });
                });
                
            }
               
            showDismissed = !showDismissed;
        },
    },
    async mounted() {
        setCurrentPageTitle("Insights");
        (document.getElementsByClassName("fc-toolbar-chunk")[2] as any).style.width = 
		(document.getElementsByClassName("fc-toolbar-chunk")[0] as any).offsetWidth + "px"

        try {            
            document.getElementById("dismissToggle")?.setAttribute("disabled", "disabled");

            this.loading = true;
            let today = new Date()
            let endDate = new Date(today.getFullYear(), today.getMonth() + 6, today.getDate());
			let params = {
					startDate: today.toISOString().split("T")[0],
					endDate: endDate.toISOString().split("T")[0]
					
				};

            let dismissedInsightsEntries = await ApiService.get("appointment/insights");

            for (let insight of dismissedInsightsEntries.data) {

                this.dismissedAppointmentInsights.insights.push({
                    appointmentId: insight.appointmentId,
                    procedureRuleTypeId: insight.procedureRuleType,
                    insightTypeId: insight.insightTypeId,
                });
            }            

            let res = await ApiService.query("appointment/analyze", { params });
            this.loading = false;
            
            console.log(res.data);
            if (res) {
                for (let appt of res.data) {
                    if (appt.almostDueProcedures.length === 0 && appt.noDoctor === null && appt.isBirthday === false && appt.appointmentTooLongForChild === null){
                        continue;
                    }

                    let insightsCount = 0;
                    let dismissedCount = 0;
                    let allDismissed = false;
                    
                    for(let dueProcedure of appt.almostDueProcedures) {
                        let dismissedInsights = this.dismissedAppointmentInsights.insights.filter(i => i.appointmentId == appt.appointmentId && i.procedureRuleTypeId == dueProcedure.procedureRuleTypeId).length;

                        if (insightsCount == 0) {
                            insightsCount = appt.almostDueProcedures.length;
                        }

                        dismissedCount += dismissedInsights;

                        if (dismissedCount > 0 && dismissedCount == insightsCount)
                            allDismissed = true;

                        this.appointmentInsights.insights.push({
                            appointmentId: appt.appointmentId,
                            procedureRuleTypeId:  dueProcedure.procedureRuleTypeId,
                            insightTypeId: InsightType.AlmostDue,
                            description: `${dueProcedure.procedureName} is due in ${dueProcedure.inDays} day${dueProcedure.inDays === 1 ? '' : 's'} on ${dueProcedure.dueDate.split("T")[0]}`,
                            dismissed: dismissedInsights > 0,
                        });

                    }

                    if (appt.noDoctor) {
                        let dismissedInsights = this.dismissedAppointmentInsights.insights.filter(i => i.appointmentId == appt.appointmentId && i.insightTypeId == InsightType.NoDoctor).length;        

                        if (dismissedInsights > 0)
                            allDismissed = true;

                        this.appointmentInsights.insights.push({
                            appointmentId: appt.appointmentId,
                            procedureRuleTypeId: 0,
                            insightTypeId: InsightType.NoDoctor,
                            description: `No doctor in the office. Appointment should be less than ${appt.noDoctor.recommendedMinutes}min.`,
                            dismissed: dismissedInsights > 0,
                        });
                    }

                    if (appt.appointmentTooLongForChild) {
                        let dismissedInsights = this.dismissedAppointmentInsights.insights.filter(i => i.appointmentId == appt.appointmentId && i.insightTypeId == InsightType.Under12).length;  

                        if (dismissedInsights > 0)
                            allDismissed = true;

                        this.appointmentInsights.insights.push({
                            appointmentId: appt.appointmentId,
                            procedureRuleTypeId: 0,
                            insightTypeId: InsightType.Under12,
                            description: `Patient is less than 12yo. Appointment should be less than ${appt.appointmentTooLongForChild.recommendedMinutes}min.`,
                            dismissed: dismissedInsights > 0,
                        });
                    }

                    if (appt.isBirthday) {
                        let dismissedInsights = this.dismissedAppointmentInsights.insights.filter(i => i.appointmentId == appt.appointmentId && i.insightTypeId == InsightType.Birthday).length;

                        if (dismissedInsights > 0)
                            allDismissed = true;

                        this.appointmentInsights.insights.push({
                            appointmentId: appt.appointmentId,
                            procedureRuleTypeId: 0,
                            insightTypeId: InsightType.Birthday,
                            description: "Happy Birthday",
                            dismissed: dismissedInsights > 0,
                        });
                    }

                    this.calendarOptions.events.push({   
                        apptId: appt.appointmentId,               
                        title: appt.patientInitials,
                        start: appt.date,
                        formattedStart: this.FormatDate(appt.date),
                        providerId: appt.providerId,
                        hasNoDoctor: appt.noDoctor !== null,
                        hasAlmostDueProcs:  appt.almostDueProcedures.length > 0,
                        isBirthday:  appt.isBirthday,
                        appointmentTooLongForChild: appt.appointmentTooLongForChild,
                        insights: this.appointmentInsights.insights.filter(ins => ins.appointmentId == appt.appointmentId),
                        allInsightsDismissed: allDismissed,
                    });
                }
                //this.calendarKey++;
            }
            if (res.status !== 200) {
                //this.toast.error(codes.error)
            }            
            

        } catch (e) {
            console.log(e)
            //this.toast.error(codes.error)
        } finally {
            document.getElementById("dismissToggle")?.removeAttribute("disabled");
        }
    }
});
